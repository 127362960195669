import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { GlobalVariable } from "../config/global";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  getValidateIfReferenceExists(
    reference: string
  ): Observable<any> {
    return this.http
      .get(
        buildGetValidateIfReferenceExists(
          GlobalVariable.PAYMENT_VALIDATEIFREFERENCEEXISTS_URL,
          reference
        ),
        {
          headers: this.getHeadersNA(),
        }
      )
      .pipe(catchError((error) => of(`Bad Promise: ${error}`)));
  }

  getSecretIntegrityEncrypt(
    reference: string,
    amount: string,
    currency: string,
    payment_integrity: string
  ): Observable<any> {
    return this.http
      .get(
        buildGetSecretIntegrityEncryptUrl(
          GlobalVariable.PAYMENT_GENERATESECRETINTEGRITYHASH_URL,
          reference,
          amount,
          currency,
          payment_integrity
        ),
        {
          headers: this.getHeadersNA(),
        }
      )
      .pipe(catchError((error) => of(`Bad Promise: ${error}`)));
  }

  post(submission: any): Observable<any> {
    return this.http
      .post(buildPOSTUrl(GlobalVariable.PAYMENT_CREATE_URL), submission, {
        headers: this.getHeadersNA(),
      })
      .pipe(catchError((error) => of(`Bad Promise: ${error}`)));
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders();
    headers.set("Accept", "application/json");
    headers.set(
      "Access-Control-Allow-Methods",
      "GET, POST, DELETE, PUT, OPTIONS"
    );
    headers.set("Access-Control-Allow-Origin", "*");
    return headers;
  }
}

function buildGetValidateIfReferenceExists(
  type: string,
  reference: string
): string {
  let finalUrl = GlobalVariable.BASE_API_URL;
  finalUrl += type;
  finalUrl += "?reference=" + reference;
  return finalUrl;
}

function buildGetSecretIntegrityEncryptUrl(
  type: string,
  reference: string,
  amount: string,
  currency: string,
  payment_integrity: string
): string {
  let finalUrl = GlobalVariable.BASE_API_URL;
  finalUrl += type;
  finalUrl += "?reference=" + reference;
  finalUrl += "&amount=" + amount;
  finalUrl += "&currency=" + currency;
  finalUrl += "&payment_integrity=" + payment_integrity;
  return finalUrl;
}

function buildPOSTUrl(type: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL;
  finalUrl += type;
  return finalUrl;
}
